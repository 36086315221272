// // Configure Firebase.
// import firebase from 'firebase';
// // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider, EmailAuthProvider,
    createUserWithEmailAndPassword,
    getAuth, deleteUser,
    signInWithEmailAndPassword, signInWithPopup,

} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAZ8VPLme4QuXza1CrcD_q4yZjhUwmr08c",
    authDomain: "firescape-platform.firebaseapp.com",
    projectId: "firescape-platform",
    storageBucket: "firescape-platform.appspot.com",
    messagingSenderId: "188214697001",
    appId: "1:188214697001:web:b05da98e6603edb62fd038"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID
    ],
};

async function signInWithGoogle() {
    // const auth = new Auth();
    const googleProvider = new GoogleAuthProvider();
    return await signInWithPopup(auth, googleProvider).then((res) => {
        console.log(res);
        return res.user;
    });
}

async function signInWithEmail(email, password) {
    // const emailProvider = new EmailAuthProvider();
    return await signInWithEmailAndPassword(auth, email, password).then((res) => {
        return res.user;
    })
}

async function createUserWithEmail(email, password) {
    return await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            return user;
        });
}

async function signOut() {
    return await auth.signOut();
}

const analytics = getAnalytics();

async function deleteAccount() {
    // todo: reauthenticate
    const user = auth.currentUser;
    // Todo: other data deletion
    return await deleteUser(user);
}

export {
    signInWithGoogle,
    analytics,
    signInWithEmail,
    deleteAccount,
    createUserWithEmail, 
    app, auth, 
    firebaseConfig, 
    signOut
}