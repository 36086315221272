// assets
import { IconDashboard, IconDeviceAnalytics, IconAdjustments, IconChecks } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconAdjustments,
    IconChecks
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: 'Home Hardening',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/app/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'optimize',
            title: 'Optimize',
            type: 'item',
            url: '/app/optimize/default',
            icon: icons.IconChecks,
            breadcrumbs: true
        },
        {
            id: 'neighborhood',
            title: 'Neighborhood',
            type: 'item',
            url: '/app/neighborhood/default',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: true  
        }
    ]
};

export default dashboard;
