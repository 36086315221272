// assets
import { IconDashboard, IconDeviceAnalytics, IconSettings, IconUser, IconInfoCircle } from '@tabler/icons';

// constant
const icons = {
    IconSettings,
    IconInfoCircle
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const about = {
    id: 'about',
    title: 'More',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/app/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'help',
            title: 'Help',
            type: 'item',
            url: '/app/help',
            icon: icons.IconInfoCircle,
            breadcrumbs: false
        }
    ]
};

export default about;
