import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import { SET_USER } from 'store/actions'; // THEME_RTL

import { auth } from 'services/firebase';

// routing
import { MainRoutes } from './routes';

// defaultTheme
import themes from './themes';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';

// project imports
import NavigationScroll from './layout/NavigationScroll';

// ===========================|| APP ||=========================== //

function App() {
    const customization = useSelector((state) => state.customization);
    const [isLoading, setIsLoading] = React.useState(true);
    const dispatch = useDispatch();

    React.useEffect(async () => {
        return new Promise((resolve, reject) => {
            auth.onAuthStateChanged((user) => {
                dispatch({ type: SET_USER, user });
                setIsLoading(false);
                return resolve(user);
            })
        })
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    {isLoading ? <SkeletonPopularCard /> : <MainRoutes></MainRoutes>}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
