import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import { getMainRoutes } from './MainRoutes';

// ===========================|| ROUTING RENDER ||=========================== //

export function MainRoutes() {
    const customization = useSelector((state) => state.customization);
    const loggedIn = (customization.user) ? true : false;
    const theRoutes = getMainRoutes(loggedIn);
    return useRoutes(theRoutes);
}