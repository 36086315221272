import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// project imports
import MinimalLayout from 'layout/MinimalLayout';
import { Typography } from '@material-ui/core';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const OptimizeDefault = Loadable(lazy(() => import('views/optimize/Default')));
const UploadPage = Loadable(lazy(() => import('views/Upload')));
const CommunityPage = Loadable(lazy(() => import('views/Community')));
const NeighborhoodPage = Loadable(lazy(() => import('views/Neighborhood')));


const DatasetsPage = Loadable(lazy(() => import('views/Datasets')));
const SettingsPage = Loadable(lazy(() => import('views/Settings')));
const HelpPage = Loadable(lazy(() => import('views/HelpPage')));
const NotFoundPage = Loadable(lazy(() => import('views/NotFoundPage')));
const LandingPage = Loadable(lazy(() => import('views/LandingPage')))

const InvitationForm = Loadable(lazy(() => import('views/SingleInput')));

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ===========================|| MAIN ROUTING ||=========================== //

export function getMainRoutes(isLoggedIn) {
    return [
        { path: '/', element: <LandingPage></LandingPage> },
        {
            path: '/app',
            element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
            children: [
                {
                    path: '/dashboard',
                    element: <DashboardDefault />
                },
                {
                    path: '/dashboard/default',
                    element: <DashboardDefault />
                },
                {
                    path: '/dashboard/:batchID',
                    element: <DashboardDefault />
                },
                {
                    path: '/optimize/default',
                    element: <OptimizeDefault />
                },
                {
                    path: '/optimize/:batchID',
                    element: <OptimizeDefault />
                },
                {
                    path: '/community/default',
                    element: <CommunityPage />
                },
                {
                    path: '/community/:batchID',
                    element: <CommunityPage />
                },
                {
                    path: '/neighborhood/default',
                    element: <NeighborhoodPage />
                },
                {
                    path: '/neighborhood/:batchID',
                    element: <NeighborhoodPage />
                },
                {
                    path: '/upload',
                    element: <UploadPage />
                },
                {
                    path: '/datasets',
                    element: <DatasetsPage />
                },
                {
                    path: '/help',
                    element: <HelpPage />
                },
                {
                    path: '/settings',
                    element: <SettingsPage />
                },
                {
                    path: '/*',
                    element: <NotFoundPage />
                }
            ]
        },
        {
            path: '/',
            element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/app/dashboard" />,
            children: [
                { path: '/login', element: <AuthLogin /> },
                { path: '/register', element: <AuthRegister /> },
                { path: '/app/*', element: <Navigate to="/login" /> }
            ],
        },
        {
            path: '/community',
            element: <MinimalLayout />,
            children: [
                { path: '/invite', element: <InvitationForm /> },
            ],
        },
        {
            path: '/*',
            element: <NotFoundPage />
        }
    ]
}

