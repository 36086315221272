// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconFileUpload  } from '@tabler/icons';

// constant
const icons = {
    IconFileUpload
};

const other = {
    id: 'data_management',
    title: 'Data Management',
    type: 'group',
    children: [
        {
            id: 'upload',
            title: 'Upload',
            type: 'item',
            url: '/app/upload',
            icon: icons.IconFileUpload,
            breadcrumbs: true
        },
        {
            id: 'datasets',
            title: 'My Datasets',
            type: 'item',
            url: '/app/datasets',
            icon: icons.IconFileUpload,
            breadcrumbs: true
        }
    ]
};

export default other;
