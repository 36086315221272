import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import about from './about';
import other from './other';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [dashboard, other, about]
};

export default menuItems;
 